<template>
  <div>
    <form @submit.prevent="submitCreate">
      <b-card class="mb-1">
        <div class="form-row">
          <div class="col-md-4">
            <label>
              <i class="text-danger bi bi-record-circle"></i>
              Nome
            </label>
            <div class="form-group mb-md-0">
              <input
                type="text"
                v-model="form.name"
                :class="{ 'is-invalid': $v.form.name.$error }"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Prioridade
                <i
                  class="bi bi-question-circle"
                  v-b-tooltip.hover.top="
                    'Dfine a ordem(1ª, 2ª e etc..) da adquirente. Caso a adquirente esteja fora do ar.'
                  "
                ></i>
              </label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="optionsPriorities"
                :searchable="false"
                v-model="form.priority"
                :class="{ 'is-invalid': $v.form.priority.$error }"
              />
            </div>
          </div>
        </div>
      </b-card>

      <div
        class="card shadow-none border-0 mb-1 rounded-0 card-header-custom-actions"
      >
        <div class="card-header">
          <div class="form-row">
            <div class="col-md-10 col-8">
              <h4 class="mb-0">
                <i class="text-danger bi bi-record-circle"></i>
                Taxas
                <small class="d-block">Cadastre a taxa cobrada por bandeira, método de pagamento e por parcela. </small>
              </h4>
            </div>
            <div class="col-md-2 col-4">
              <button
                type="button"
                class="btn btn-primary btn-block"
                @click="addNewFee"
              >
                <i class="bi bi-plus mr-25"></i>
                <span>Novo</span>
              </button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div
            class="form-row"
            v-for="(element, index) in form.fees"
            :key="index"
          >
            <div class="col-md-3">
              <div
                class="form-group"
                :class="form.fees.length === 1 ? 'mb-md-0' : ''"
              >
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="element.card_network"
                  :options="optionsCardNetworks"
                  :searchable="false"
                  placeholder="Bandeira"
                  :class="{
                    'is-invalid': $v.form.fees.$each[index].card_network.$error,
                  }"
                >
                </v-select>
              </div>
            </div>

            <div class="col-md-4">
              <div
                class="form-group"
                :class="form.fees.length === 1 ? 'mb-md-0' : ''"
              >
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="element.payment_method"
                  :options="optionsPaymentMethods"
                  :searchable="false"
                  placeholder="Método de pagamento"
                  :class="{
                    'is-invalid':
                      $v.form.fees.$each[index].payment_method.$error,
                  }"
                >
                </v-select>
              </div>
            </div>

            <div class="col-md-2">
              <div
                class="form-group"
                :class="form.fees.length === 1 ? 'mb-md-0' : ''"
              >
                <input
                  type="number"
                  class="form-control text-center"
                  v-model="element.installment"
                  placeholder="Parcela"
                  :class="{
                    'is-invalid': $v.form.fees.$each[index].installment.$error,
                  }"
                />
              </div>
            </div>

            <div class="col-md-2">
              <div
                class="form-group"
                :class="form.fees.length === 1 ? 'mb-md-0' : ''"
              >
                <money
                  class="form-control text-center"
                  v-bind="money"
                  v-model="element.fee"
                  placeholder="Taxa %"
                  :class="{
                    'is-invalid': $v.form.fees.$each[index].fee.$error,
                  }"
                ></money>
              </div>
            </div>

            <div class="col-md-1" v-if="form.fees.length > 1">
              <button
                type="button"
                style="padding: 4px 9px !important; height: 44px"
                class="btn btn-block btn-sm text-center btn-danger text-danger"
                @click="removeAt(index)"
              >
                <feather-icon icon="XCircleIcon" size="16" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions"
      >
        <div class="card-header">
          <div class="form-row">
            <div class="col-md-10 col-8">
              <h4 class="mb-0">
                <i class="text-danger bi bi-record-circle"></i>
                Unidades
                <small class="d-block"
                  >Selecione 1 ou mais unidades que utilizarão as taxas
                  cadastradas acima</small
                >
              </h4>
            </div>
            <div class="col-md-2 col-4">
              <button
                type="button"
                class="btn btn-primary btn-block"
                @click="$bvModal.show('modal-add-unit')"
              >
                <span>Incluir</span>
              </button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="form-row">
            <div
              class="col-md-3"
              v-for="(element, index) in form.units"
              :key="index"
            >
              <div
                class="form-group"
                :class="form.units.length === 1 ? 'mb-md-0' : ''"
              >
                <div class="btn-group btn-group-units-acquirers">
                  <v-select
                    label="title"
                    item-text="title"
                    item-value="code"
                    v-model="element.title"
                    :options="optionsUnits"
                    :searchable="false"
                    placeholder="Unidade"
                  >
                  </v-select>
                  <button
                    v-if="form.units.length > 1"
                    type="button"
                    :disabled="loading.unitDel"
                    class="btn btn-sm text-center btn-danger text-danger"
                    @click="removeUnit(element.code)"
                  >
                    <feather-icon icon="XCircleIcon" size="16" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row justify-content-end mt-2">
        <div class="col-md-4">
          <ButtonsActionsFooter
            routerBack="acquirers-list"
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </form>

    <b-modal
      id="modal-add-unit"
      modal-class="modal-create"
      title="Adicionar unidade"
      centered
      size="sm"
      hide-footer
    >
      <b-form @submit.prevent="submitAddUnits">
        <div class="form-row mb-2">
          <div class="col-md-7">
            Adicione 1 ou mais unidades
          </div>

          <div class="col-md-5">
            <button @click="addNewUnit" type="button" class="btn btn-sm btn-block btn-primary">
              Adicionar
            </button>
          </div>
        </div>
        <div class="form-row">
          <div
            class="col-md-12"
            v-for="(element, index) in formUnits"
            :key="index"
          >
            <div
              class="form-group"
              :class="formUnits.length === 1 ? 'mb-md-0' : ''"
            >
              <div class="btn-group btn-group-units-acquirers">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="element.unit"
                  :options="optionsUnits"
                  :searchable="false"
                  placeholder="Unidade"
                  :class="{
                    'is-invalid': $v.formUnits.$each[index].unit.$error,
                  }"
                >
                </v-select>
                <button
                  v-if="formUnits.length > 1"
                  type="button"
                  class="btn btn-sm text-center btn-danger text-danger"
                  @click="removeAtUnit(index)"
                >
                  <feather-icon icon="XCircleIcon" size="16" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end mt-2 pb-1">
          <div class="col-md-3">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              block
            >
              Fechar
            </b-button>
          </div>
          <div class="col-md-4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
              block
              :disabled="loading.unitAdd"
            >
              <div v-if="loading.unitAdd">
                <b-spinner small variant="light" /> salvando
              </div>
              <div v-else>Cadastrar</div>
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BCardText,
  BButton,
  VBTooltip,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { Money } from "v-money";
import {
  required,
  minLength,
} from "vuelidate/lib/validators";
import Ripple from "vue-ripple-directive";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  validations: {
    formUnits: {
      $each: {
          unit: {
            required,
          }
        },
    },
    form: {
      name: {
        required,
        minLength: minLength(5),
      },
      priority: {
        required,
      },
      fees: {
        $each: {
          card_network: {
            required,
          },
          payment_method: {
            required,
          },
          installment: {
            required,
          },
          fee: {
            required,
          },
        },
      }
    },
  },
  components: {
    BCard,
    BTable,
    BPagination,
    BCardText,
    BButton,
    vSelect,
    Money,
    BForm,
    ButtonsActionsFooter,
  },
  data() {
    return {
      loading: {
        unitDel: false,
        unitAdd: false,
      },
      optionsCardNetworks: [],
      optionsPaymentMethods: [],
      optionsPriorities: [],
      optionsUnits: [],
      optionsUnitsOriginals: [],
      formUnits: [
        {
          id: '',
        }
      ],
      form: {
        fees: [
          {
            card_network: "",
            payment_method: "",
            installment: "",
            fee: "",
          },
        ],
        units: [],
        priority: "",
        name: "",
      },
      money: {
        decimal: ".",
        thousands: ".",
        prefix: "",
        suffix: "%",
        precision: 2,
        masked: false,
      },
      submited: false,
    };
  },
  mounted() {
    this.getData()

    this.$store.dispatch("CardNetwork/forSelect").then((res) => {
      this.optionsCardNetworks = res;
    });

    this.$store
      .dispatch("CardNetwork/forSelectPaymentMethodConventional")
      .then((res) => {
        this.optionsPaymentMethods = res;
      });

    for (let index = 1; index <= 5; index++) {
      this.optionsPriorities.push({
        code: index,
        title: `${index}º`,
      });
    }

    this.$store.dispatch("Unit/forSelect", 1).then((res) => {
      this.optionsUnits = res;
    });
  },
  watch: {
    'form.units': function(newValue) {
      console.log(newValue)
    }
  },
  methods: {
    async getData() {
      this.$store.dispatch("Acquirer/show", this.$route.params.uuid).then((res) => {
        this.form = res;
      });
    },
    async submitAddUnits () {
      this.$v.formUnits.$touch();

      if (!this.$v.formUnits.$error) {
        this.loading.unitAdd = true;

        this.$store.dispatch("Acquirer/addUnits", { 
          uuid: this.$route.params.uuid,
          data: { units: this.formUnits }
        }).then((res) => {
          if (res) {
            this.notifyDefault("success");
            this.$bvModal.hide('modal-add-unit')
            this.formUnits = [];
            this.getData()
          }
        })
        .catch(() => {
          this.loading.unitAdd = false;
        })
        .finally(() => {
          this.loading.unitAdd = false;
        });
      }
    },
    addNewUnit() {
      this.formUnits.push({
        code: '',
      });
    },
    addNewFee() {
      this.form.fees.push({
        card_network: "",
        payment_method: "",
        installment: "",
        fee: "",
      });
    },
    removeAt(idx) {
      this.form.fees.splice(idx, 1);
    },
    removeUnit(uuid) {
      this.$store
        .dispatch("Acquirer/deleteUnit", { uuid: this.$route.params.uuid, unit: uuid })
        .then((res) => {
          if (res) {
            this.notifyDefault("success");
            this.getData()
          }
        });
    },
    removeAtUnit(idx) {
      this.form.units.splice(idx, 1);
    },

    submitCreate() {
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        this.submited = true;

        this.$store
          .dispatch("Acquirer/update", { uuid: this.$route.params.uuid, data: this.form })
          .then(() => {
            this.$router.push({ name: "acquirers-list" });
            this.notifyDefault("success");
          })
          .finally(() => {
            this.submited = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.btn-group-units-acquirers {
  width: 100%;
  .v-select {
    width: 100%;
    .vs__dropdown-toggle {
      border-radius: 3px 0px 0px 3px !important;
    }
  }

  .btn {
    padding: 4px 9px !important;
    width: 50px;
    border-radius: 0px 3px 3px 0px !important;
  }
}
</style>
